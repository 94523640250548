.language-switcher {
    display: flex;
    line-height: 33px;
}
.language-switcher__label {

}
.language-switcher__button {
    border-radius: 5px;
    margin-left: 15px;
    border: 0;
    background: transparent;
    cursor: pointer;
    min-width: 30px;
    transition: all 0.4s ease;
}
.language-switcher__button--active {
     background: #3642AE;
     color: #FFFFFF;
}