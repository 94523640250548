.card {
  background: #ffffff;
  /* box-shadow: 0px 0px 10px #0000000d; */
  border: 1px solid #e2e4ea;
  border-radius: 10px;
  padding: 20px 40px;

  position: relative;

  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #6a7187;
}

.card__footer {
  background: #f4f5f6;
  margin: 50px -40px -20px;
  padding: 17px 40px;
  font-weight: 300;
  font-size: 16px;
  width: -webkit-fill-available;
}
.card__footer a {
  color: #3642ae;
}

.card__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  color: #6a7187;
}
.card__item--button {
  border: 1px solid #e2e4ea;
  border-radius: 10px;
  background: transparent;
  text-align: left;
  padding: 20px;
}

.card__item__icon {
  margin-right: 20px;
}
.card__item__text {
}
.card__item__title {
  font-size: 16px;
  line-height: 18px;
  color: black;
  vertical-align: text-top;
}
.card__item__desc {
  font-weight: 300;
}
