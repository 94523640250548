.card-form {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.card-form__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card-form__element {
  margin-bottom: 25px;
}
.card-form__element--details {
  width: calc(50% - 10px);
}
.card-form__label {
  color: black;
  font-weight: 500;
  margin-bottom: 8px;
}
.card-form__input {
  border: 1px solid #e2e4ea;
  border-radius: 10px;
  padding: 13px;
}

.card-form__submit {
  font-size: 15px;
  background: #3642ae;
  color: white;
  border-radius: 10px;
  border: 0;
  padding: 16px 32px;
  width: 180px;
}

.card-back-button {
  prefix: "<";
  font-size: 21px;
  border: none;
  background-color: inherit;
  color: black;
  font-weight: bold;
  margin-top: 10px;
  text-align: left;
}
