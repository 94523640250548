.payment-details {

}

.payment-details__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    color: black;
}

.payment-details__summary {
    border-top: 1px solid #E2E4EA;
    font-weight: 600;
    padding-top: 17px;
    display: flex;
}

.payment-details__list {

}

.payment-details__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.payment-details__label {
    color: #6A7187;
}
.payment-details__label--summary {
    color: black;
}

.payment-details__value {
    white-space: nowrap;
    color: black;
    font-weight: 500;
    margin-left: auto;
    padding-left: 10px;
}
.payment-details__value--summary {
    font-size: 16px;
    color: #3D9629;
}