.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.flex-item {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  flex: 1;
  margin: 10px;
}

.justify-end {
  justify-content: flex-end;
}

.m-0 {
  margin: 0 !important;
}
.mt-1 {
  margin-top: 10px !important;
}
.mb-1 {
  margin-bottom: 10px !important;
}
.ml-1 {
  margin-left: 10px !important;
}
.mr-1 {
  margin-right: 10px !important;
}
.mt-2 {
  margin-top: 20px !important;
}
.mb-2 {
  margin-bottom: 20px !important;
}
.ml-2 {
  margin-left: 20px !important;
}
.mr-2 {
  margin-right: 20px !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
