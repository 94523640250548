.status-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 40px 50px 0px 50px;
}

.status-card__message {
  margin: 15px 0 0;
  font-weight: 600;
  font-size: 21px;
  text-align: center;
}

.status-card__fallback {
  margin-top: 30px;
  display: flex;
  background: #3642ae;
  color: white;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
}

.status-card__fallback:hover {
  text-decoration: none;
  color: white;
}
