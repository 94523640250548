.header {
    height: 100px;
    width: 100%;
    background: #F4F5F6;
    border-bottom: 1px solid #E2E4EA;
}
.header__wrapper {
    display: flex;
    width: 1232px;
    height: 100%;
    padding: 0 18px;
    margin: 0px auto;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1260px) {
    .header__wrapper {
        width: 100%;
    }
}
@media screen and (max-width: 500px) {
    .header {
        min-height: 100px;
        height: auto;
    }
    .header__wrapper {
        flex-direction: column;
        padding: 0 0 10px;
    }
    .header__logo {
        margin: 10px;
    }
}