.card-section {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e4ea;
  border-radius: 10px;
  padding: 25px 20px;
}

.card-section__separator {
  width: 100%;
  border-top: 1px solid #e2e4ea;
  margin-top: 10px;
}

.card-section__text {
  margin: 10px 0;
  color: black;
  font-weight: bolder;
}
