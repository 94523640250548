.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 116px;
    height: 116px;box-shadow: 0px 0px 10px #00000029;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #6A7187;
}
.loading__spinner {
     width: 42px;
     height: 42px;
     border-radius: 50%;
     border: 4px solid #E2E4EA;
     border-top: 4px solid #26BFB8;
     animation: spin 1.1s linear infinite;
}
.loading__label {
     margin-top: 12px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}