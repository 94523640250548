.wrapper {
  display: grid;
  column-gap: 25px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 18px;
}

.wrapper--small {
  grid-template-columns: 291px 650px 291px;
}

.wrapper--big {
  grid-template-columns: 241px 750px 241px;
}

.column {
  gid-column: 1;
  /*width: 340px;*/
}

.container {
  grid-column: 2;
  /*width: 470px;*/
  /*margin: 0 25px;*/
}

.main {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100vh;
  background-color: #f4f5f6;
  background-image: url("../../../assets/background.svg");
  background-position: center bottom;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1260px) {
  .wrapper {
    grid-template-columns: 1fr;
    column-gap: 25px;
    width: 100%;
    margin: 0 auto 20px;
  }
  .container {
    grid-column: 1;
  }
}
