.hybrid-payment {
}

.hybrid-payment__title {
  font-size: 21px;
  color: black;
  margin: 10px 0 30px;
}

.hybrid-payment__info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 30px;

  border: 1px solid #e2e4ea;
  border-radius: 10px;
  padding: 15px 20px;
}
.hybrid-payment__info__icon {
  margin-right: 10px;
}
