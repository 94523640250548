.card-select__info {
  display: flex;
  flex-flow: column nowrap;
}

.card-select__container {
  background-color: #f4f5f6;
  margin-top: 10px;
  padding: 10px;
}

.card-select__rows-container {
  margin-top: 10px;
  padding: 15px;
}

.card-select__header {
  color: black;
  font-weight: bolder;
}

.card-select__separator {
  width: 100%;
  border-top: 1px solid #e2e4ea;
  margin-top: 10px;
}

.card-select__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.card-select__card--small {
  flex-direction: column;
}

.card-select__card--small div {
  margin-top: 10px;
}

.card-select__row--blocked {
  background-color: lightgray;
  cursor: initial;
  background-color: #f4f5f6;
  border-radius: 10px;
}

.card-select__card {
  display: flex;
  align-items: center;
}

.card-select__button {
  align-self: center;
  font-size: 15px;
  background: #3642ae;
  color: white;
  border-radius: 10px;
  border: 0;
  padding: 16px 32px;
  width: 180px;
}

.card-select__button:disabled {
  background-color: #f4f5f6;
  color: inherit;
  cursor: not-allowed;
}

.card-select__button :hover {
  text-decoration: none;
  color: white;
}

.card-select__input--radio {
  scale: 1.2;
  margin: 10px;
}

.card-select__pagination-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.card-select__pagination-page {
  padding: 3px 8px;
}

.card-select__pagination-arrow {
  padding: 0px 3px;
}

.card-select__pagination-arrow svg {
  width: 10px;
}

.card-select__no-card-message {
  font-weight: bolder;
  text-align: center;
  margin: auto;
  display: block;
}

.card-select__pagination-active {
  background-color: #3642ae;
  padding: 3px 8px;
  color: white;
  border-radius: 5px;
}

.card-select__previousLabel--left {
  transform: rotate(180deg);
}
