.not-found {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 312px;
  max-width: 100%;
  /* box-shadow: 0px 0px 10px #00000029; */
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: 700;
  color: #000000;
  padding: 30px;
}
