.update-recurring__title {
  font-size: 21px;
  color: black;
  margin: 10px 0 30px;
}

.update-recurring__info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 30px;

  border: 1px solid #e2e4ea;
  border-radius: 10px;
  padding: 15px 20px;
}

.update-recurring__header {
  margin-bottom: 30px;
}

.update-recurring__info__icon {
  margin-right: 10px;
}

.update-recurring__method {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  border: 1px solid #e2e4ea;
  border-radius: 10px;
  padding: 15px 20px;
}

.update-recurring__payment-info {
  display: flex;
  flex-flow: row nowrap;
}

.update-recurring h2 {
  display: flex;
}

.update-recurring__edit-button {
  margin-top: 10px;
  display: flex;
  background: #3642ae;
  color: white;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  border: none;
}

.update-recurring__edit-button:hover {
  text-decoration: none;
  color: white;
}

.update-recurring__payment-details {
  display: flex;
  flex-flow: column nowrap;
}

.update-recurring__payment-details > div {
  display: flex;
  flex-flow: row nowrap;
  margin: 10px 0;
}

.update-recurring__payment-details > div > span:first-child {
  width: 70px;
}

.update-recurring__separator {
  width: 100%;
  border-top: 1px solid #e2e4ea;
}

.update-recurring__separator {
  width: 100%;
  border-top: 1px solid #e2e4ea;
  margin-top: 10px;
}

.update-recurring__card-details {
  display: flex;
  flex-flow: column nowrap;
}

.update-recurring__text {
  margin: 10px 0;
  color: black;
  font-weight: bolder;
}

.update-recurring__back-button {
  position: relative;
  top: -50px;
  left: -40px;
  display: block;
  color: #6a7187;
  border: none;
  background-color: transparent;
  text-align: left;
}
